import React, { useState, useRef } from 'react';

import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

import { MobileFeatureCard } from './MobileFeatureCard';

const useStyles = makeStyles((theme) => ({
	header: {
		lineHeight: 1.4,
		fontWeight: 700,
		color: '#002D5C',
		paddingBottom: '1rem',
	},
}));

export const MobileFeatures = ({
	featureSection,
	featureRef,
	accentColor = '#002D5C',
	fs,
}) => {
	const classes = useStyles();
	const mobileCardRef = useRef(null);
	const [expanded, setExpanded] = useState(0);

	const handleChange = async (index) => {
		await setExpanded(index);
		mobileCardRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};
	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={4}
				style={{ padding: '4rem 0 0 0' }}>
				<div ref={mobileCardRef} />
				{featureSection.map((feature, index) => (
					<MobileFeatureCard
						feature={feature}
						index={index}
						key={index}
						featureRef={featureRef}
						accentColor={accentColor}
						expanded={expanded}
						handleChange={handleChange}
						fs={fs}
					/>
				))}
			</Grid>
		</>
	);
};
