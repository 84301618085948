import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	topHeader: {
		color: theme.lightBlue,
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
	},
	bottomHeader: {
		color: theme.workwaveBlue,
		fontWeight: 700,
		paddingTop: '1rem',
		// paddingBottom: '1rem',
	},
  linkText: {
    color: theme.mediumDarkGray,
  },
}));

export const ProductOverview = ({ overviewBody, overviewImage }) => {
	const classes = useStyles();
	const med = useMediaQuery('(max-width:600px)');
	return (
		<Grid container direction='row' justifyContent='space-evenly' spacing={1}>
			{!!overviewImage ? (
				<Grid
					item
					container
					direction='row'
					alignItems='center'
					justifyContent='center'
					style={{ marginBottom: med && '2rem' }}
					xs={12}
					md={5}>
					<GatsbyImage image={overviewImage?.asset?.gatsbyImageData} />
				</Grid>
			) : null}
			<Grid
				item
				container
				xs={12}
				md={!overviewImage ? 10 : 6}
				spacing={2}
				direction='column'
				justifyContent='center'
				alignItems='flex-start'>
				{/* <Grid item>
					<Typography
						variant='h2'
						color='primary'
						style={{
							lineHeight: 1.4,
						}}>
						{overviewHeader}
					</Typography>
				</Grid> */}
				<Grid item style={{ zIndex: 1000 }}>
					{overviewBody?.map((content, index) => (
						<PortableText
							key={index}
							content={content}
							serializers={{
								h2: ({ children }) => (
									<Typography variant='h2' className={classes.bottomHeader}>
										{children}
									</Typography>
								),
								h4: ({ children }) => (
									<Typography variant='h4' className={classes.topHeader}>
										{children}
									</Typography>
								),
								normal: ({ children }) => (
									<Typography
										variant='body1'
										style={{
											color: '#4B5B69',
											lineHeight: 1.7,
											fontFamily: 'Roboto',
										}}>
										{children}
									</Typography>
								),
								internalLink: ({ children, internalSlug }) => (
									<Link to={`/${internalSlug}`} className={classes.linkText}>
										{children}
									</Link>
								),
							}}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
