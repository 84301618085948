import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	firstLine: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
		fontWeight: 700,
	},
	secondLine: {
		fontSize: '3.75rem',
		fontWeight: 700,
		color: '#2a7abc',
		textTransform: 'uppercase',
	},
	paragraph: {
		marginRight: '7em',
		lineHeight: '32px',
		marginTop: '1rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		[theme.breakpoints.down('lg')]: {
			marginRight: '4em',
		},
		[theme.breakpoints.down('md')]: {
			marginRight: '0',
		},
	},
}));

export const Overview = ({ overview, overviewImage }) => {
	const classes = useStyles();
	const lg = useMediaQuery('(max-width: 1280px)');
	return (
		<Grid container direction='row' justifyContent='center' style={{ marginTop: lg ? '3rem' : 'auto' }}>
			<Grid
				container
				item
				direction='column'
				justifyContent='center'
				alignItems='center'
				xs={12}
				lg={7}>
				<PortableText
					content={overview}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								color='primary'
								className={classes.firstLine}
								style={{ lineHeight: '1.0' }}>
								{children}
							</Typography>
						),
						em: ({ children }) => (
							<span className={classes.secondLine}>{children}</span>
						),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.paragraph}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>
			<Grid
				container
				item
				direction='column'
				justifyContent='center'
				alignItems='center'
				xs={12}
				lg={5}>
				<GatsbyImage
					image={overviewImage?.asset?.gatsbyImageData}
					imgStyle={{ objectFit: 'contain' }}
				/>
			</Grid>
		</Grid>
		// <div style={{ padding: '2rem 0' }}>
		// </div>
	);
};
